import React from "react"
import { Seo } from "../../components/seo"
// Img
import logoMexico from "../../images/919_mex_logo_800_retina.webp"
import homeQuick from"../../images/home/home-quick.svg"
import homeQuickShadow from"../../images/home/home-quick-shadow.svg"

const AboutEs = () => {
  return (
    <>
      <Seo title={`Página sobre nosotros`} language={"es"} />
      <section className="container about-en english-page-body">
        <div className="row">
          <div className="en-pages">
            <h1>Sobre Nosotros</h1>

            <section className="mb-30">
              <div className="about-quick max-width-size">
                <div className="about-quick_imageshape">
                  <img src={homeQuick} alt="quick global" className="image" />
                  <img
                    src={homeQuickShadow}
                    alt="quick global"
                    className="shape"
                  />
                </div>
                <div>
                  <img src={logoMexico} alt="main-logo" />
                  <p>
                    Somos una compañía japonesa de reclutamiento y selección de
                    personal que brinda sus servicios principalmente a las
                    empresas japonesas establecidas en México. Nuestra casa
                    matriz Quick Co., Ltd (Japón) cuenta con más de 40 años de
                    experiencia en el servicio de reclutamiento y somos la única
                    compañía japonesa de dichos servicios en México cuya casa
                    matriz es cotizada en el mercado prime de la bolsa de Tokio.
                  </p>
                </div>
              </div>
            </section>
            <section className="pr-30 pl-30">
              <h2>Filosofía de gestión</h2>
              <p className="about-en_text">
                Nuestro objetivo es ser una empresa que haga felices a todos los
                involucrados. Esta filosofía es la base de tanto nuestros
                desarrollos comerciales pasados ​​como futuros. Nos esforzamos
                por hacer felices a todos aquellos relacionados con nosotros y
                con el Grupo Quick, incluidos accionistas, socios comerciales,
                solicitantes de empleo, lectores, nuestros empleados y sus
                familias, y la comunidad local, a medida que continuamos
                creciendo.
              </p>
              <h2>Filosofía de negocio</h2>
              <p className="about-en_text">
                Contribuimos a la sociedad a través del negocio de los recursos
                humanos y el de la información. Entre los cuatro principales
                recursos de gestión (personas, bienes, dinero e información),
                nuestra empresa se centra en los recursos de "personas" e
                "información" para brindar servicios especializados. Al utilizar
                de forma integral los activos comerciales de Quick Group y
                responder a las diversas necesidades de los diferentes clientes,
                contribuimos a la sociedad y mejoraremos nuestro valor como
                empresa al ser identificados como “la solución para problemas de
                gestión empresarial relacionados a recursos humanos y expansión
                de negocios.
              </p>
              <h2>Servicios</h2>
              <ul className="about-en_text">
                <li>
                  Servicios de reclutamiento en México Asesoría para empresas.
                </li>
                <li>Expansión en México</li>
              </ul>
              <h2>Dirección</h2>
              <h3>
                QUICK GLOBAL MEXICO, S.A. DE C.V. Oficina Aguascalientes
                (Oficina central)
              </h3>
              <div className="about__columns">
                <div className="about__columns__map">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3701.333403487488!2d-102.29335058555849!3d21.921730662070644!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429efadbb8daf29%3A0xcea673564e790a2b!2sCentro%20Comercial%20Punto%2045!5e0!3m2!1sen!2sbo!4v1646328779092!5m2!1sen!2sbo"
                    width="100%"
                    height="350"
                    style={{ border: 0 }}
                    loading="lazy"
                    title="Aguascalientes Office"
                  ></iframe>
                </div>
                <div className="about__columns__details">
                  <div>
                    <p className="title-red">Dirección</p>
                    <div
                      className="about__columns_col"
                      style={{ alignItems: "flex-start" }}
                    >
                      <p className="about-en_info">
                        C.C. Punto 45, Boulevard A Zacatecas 845, Oficina 510,
                        Desarrollo Especial Bulevar a Zacatecas, C.P.20116
                        Aguascalientes, Ags.
                      </p>
                    </div>
                  </div>
                  <div>
                    <p className="title-red">Teléfono</p>
                    <div className="about__columns_col">
                      <p className="about-en_info">+52-449-107-5308</p>
                    </div>
                  </div>
                  <div>
                    <p className="title-red">E-mail</p>
                    <div className="about__columns_col">
                      <p className="about-en_info">quick@919mexico.com</p>
                    </div>
                  </div>
                </div>
              </div>
              <h3>QUICK GLOBAL MEXICO, S.A. DE C.V. Oficina Querétaro </h3>
              <div className="about__columns">
                <div className="about__columns__map">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3701.333403487488!2d-102.29335058555849!3d21.921730662070644!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429efadbb8daf29%3A0xcea673564e790a2b!2sCentro%20Comercial%20Punto%2045!5e0!3m2!1sen!2sbo!4v1646328779092!5m2!1sen!2sbo"
                    width="100%"
                    height="350"
                    style={{ border: 0 }}
                    loading="lazy"
                    title="Aguascalientes Office"
                  ></iframe>
                </div>
                <div className="about__columns__details">
                  <div>
                    <p className="title-red">Dirección</p>
                    <div
                      className="about__columns_col"
                      style={{ alignItems: "flex-start" }}
                    >
                      <p className="about-en_info">
                        C.C. Punto 45, Boulevard A Zacatecas 845, Oficina 510,
                        Desarrollo Especial Bulevar a Zacatecas, C.P.20116
                        Aguascalientes, Ags.
                      </p>
                    </div>
                  </div>
                  <div>
                    <p className="title-red">Teléfono</p>
                    <div className="about__columns_col">
                      <p className="about-en_info">+52-449-107-5308</p>
                    </div>
                  </div>
                  <div>
                    <p className="title-red">E-mail</p>
                    <div className="about__columns_col">
                      <p className="about-en_info">quick@919mexico.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
    </>
  )
}

export default AboutEs
